import React from "react";

const Footer = ({ currentUser }) => {
  let currentYear = new Date().getFullYear();
  console.log(currentUser);

  let showCookieOptOut = true;

  if (
    currentUser?.branding?.WebsiteUrl === "onetrusthomeloans.com" ||
    currentUser?.branding?.WebsiteUrl === "tabormortgage.com"
  ) {
    showCookieOptOut = false;
  }

  return (
    <footer className="bg-black px-12 md:px-0  relative z-10 bottom-0 ">
      <div className="container w-5/6 mx-auto md:px-24">
        <div className="flex flex-col py-12 md:flex-row">
          <div className="mb-2 md:mb-10 md:w-1/3 md:mb-0 md:px-2 lg:w-1/4">
            <div className="text-left md:text-center">
              <ul className="list-none">
                <li className="">
                  <a
                    className="text-16 text-white font-light border-b border-transparent hover:border-white sm:text-18"
                    href="https://www.nmlsconsumeraccess.org/"
                    target="_blank"
                  >
                    NMLS Consumer Access
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-2 md:mb-10 md:w-1/3 md:mb-0 md:px-2 lg:w-1/4">
            <div className="text-left md:text-center">
              <ul id="menu-footer-second-menu" className="nav list-none">
                <li id="menu-item-1001" className=" menu-item menu-item-type-post_type menu-item-object-page">
                  <a
                    className="text-16 text-white font-light border-b border-transparent hover:border-white sm:text-18"
                    href={currentUser?.branding?.licensingURL}
                    target="_blank"
                  >
                    Locations and Licensing
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mb-2 md:mb-10 md:w-1/3 md:px-2 lg:w-1/4">
            <div className="text-left md:text-center">
              <ul id="menu-footer-third-menu" className="nav list-none">
                <li id="menu-item-174" className=" menu-item menu-item-type-custom menu-item-object-custom">
                  <a
                    target="_blank"
                    className="text-16 text-white font-light border-b border-transparent hover:border-white sm:text-18"
                    href={currentUser?.branding?.privacyPolicyURL}
                  >
                    Privacy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {showCookieOptOut && (
            <div className="mb-2 md:mb-10 md:w-1/3 md:px-2 lg:w-1/4">
              <div className="text-left md:text-center">
                <ul id="menu-footer-third-menu" className="nav list-none">
                  <li id="menu-item-174" className=" menu-item menu-item-type-custom menu-item-object-custom">
                    <a
                      target="_blank"
                      className="text-16 text-white font-light border-b border-transparent hover:border-white sm:text-18"
                      href={`https://${currentUser?.branding?.WebsiteUrl}/cookie-information-opt-out`}
                    >
                      Cookie Information & Opt-Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
          <div className="md:w-1/3 md:px-2 lg:w-1/4">
            <div className="text-left md:text-center">
              <ul id="menu-footer-third-menu" className="nav list-none">
                <li id="menu-item-174" className=" menu-item menu-item-type-custom menu-item-object-custom">
                  <a
                    target="_blank"
                    className="text-16 text-white font-light border-b border-transparent hover:border-white sm:text-18"
                    href={
                      currentUser?.details?.Encompass_Application_URL__c ||
                      currentUser?.details?.GA_Encompass_Application_URL__c
                    }
                  >
                    Client Portal
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="md:w-1/3 md:px-2 lg:w-1/4">
            <div className="text-left md:text-center">
              <ul id="menu-footer-third-menu" className="nav list-none">
                <li id="menu-item-174" className=" menu-item menu-item-type-custom menu-item-object-custom">
                  <a
                    target="_blank"
                    className="text-16 text-white font-light border-b border-transparent hover:border-white sm:text-18"
                    href={
                      currentUser?.branding?.WebsiteUrl === "calconmutualmortgage.com"
                        ? "https://onetrusthomeloans.com/navigating-your-first-mortgage-payment/"
                        : "/navigating-your-first-mortgage-payment"
                    }
                  >
                    Make a Payment
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-evenly">
          <div>
            <p className="text-white text-17 md:my-6">
              © 2015 - {currentYear} {currentUser?.branding?.name}
            </p>
          </div>
          <div className="flex flex-col md:flex-row">
            <p className="text-white text-17 md:my-6">NMLS #{currentUser?.branding?.NMLS} </p>

            <a href="https://www.nmlsconsumeraccess.org/" target="_blank" rel="noopener noreferrer">
              <p className="text-white text-17 md:my-6 px-2">(www.nmlsconsumeraccess.org)</p>
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:flex-wrap items-center pb-24">
          <div className="flex flex-col  md:flex-row items-center justify-center border border-black mt-8  ">
            <div className="px-4 py-2">
              <img
                className="w-16 h-16"
                src="https://onetrusthomeloans.com/wp-content/themes/altum/dist/images/icons/Equal_Housing_Lender_White_7d1a033e.png"
                alt="Equal Housing Lender"
              />
            </div>
            <div className="w-5/6">
              <p className="text-xs text-white md:text-left">{currentUser?.branding?.licnesing}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
